import * as Sentry from '@sentry/remix';
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import i18n from './config/i18n';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import yaml from 'js-yaml';

if (window.ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://4631efe5996eda95f284ef5e4a02d99e@o4506898335334400.ingest.us.sentry.io/4506930651136000',
    // release: '2.16.0',
    environment: window.ENVIRONMENT,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  });
}

async function hydrate() {
  const urlParams = document.location.pathname.split('/');
  const lng = urlParams.includes('en') ? 'en' : 'fr';
  await i18next
    .use(HttpApi)
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    //.use(LanguageDetector) // Setup a client-side language detector
    // Setup your backend
    .init({
      ...i18n, // spread the configuration
      returnNull: false,
      debug: false,
      lng,
      // ns: getInitialNamespaces(),
      backend: {
        loadPath: '/locales/{{lng}}.yml',
        parse: function (data: string) {
          return yaml.load(data);
        },
      },
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
